import { CurrencyAmount, Pair, Currency, ERC20Token, Token } from "@pancakeswap/sdk";
import { useEffect, useMemo, useState } from 'react'
import IPancakePairABI from 'config/abi/IPancakePair.json'
import { Interface } from '@ethersproject/abi'

import { useMultipleContractSingleData } from '../state/multicall/hooks'
import { wrappedCurrency } from '../utils/wrappedCurrency'
import { useActiveChainId } from './useActiveChainId'
import { zkChains } from "../config/chains";
import getZkLpAddress, { getZkLpAddressSync } from "../utils/getZkLpAddress";
import { toV2LiquidityToken, toZkLiquidityToken } from "../state/user/hooks";

const PAIR_INTERFACE = new Interface(IPancakePairABI)

export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID,
}

export function usePairs(currencies: [Currency | undefined, Currency | undefined][]): [PairState, Pair | null][] {
  const { chainId } = useActiveChainId()

  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId),
      ]),
    [chainId, currencies],
  )

  const pairAddresses = useMemo(
    () =>{
      return tokens.map(([tokenA, tokenB]) => {
        try {
          return tokenA && tokenB && !tokenA.equals(tokenB) ? Pair.getAddress(tokenA, tokenB) : undefined
        } catch (error: any) {
          // Debug Invariant failed related to this line
          console.error(
            error.msg,
            `- pairAddresses: ${tokenA?.address}-${tokenB?.address}`,
            `chainId: ${tokenA?.chainId}`,
          )

          return undefined
        }
      })
    },
    [tokens],
  )

  const results = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'getReserves')

  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]

      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves) return [PairState.NOT_EXISTS, null]
      const { reserve0, reserve1 } = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      return [
        PairState.EXISTS,
        new Pair(
          CurrencyAmount.fromRawAmount(token0, reserve0.toString()),
          CurrencyAmount.fromRawAmount(token1, reserve1.toString()),
        ),
      ]
    })
  }, [results, tokens])
}

export function usePairsAsync(currencies: [Currency | undefined, Currency | undefined][]): [PairState, Pair | null][] {
  const { chainId } = useActiveChainId()
  const isZk = zkChains.includes(chainId)
  const [zkPairAddresses,setZkPairAddresses] = useState<string[]>([])
  const tokens = useMemo(
    () =>
      currencies.map(([currencyA, currencyB]) => [
        wrappedCurrency(currencyA, chainId),
        wrappedCurrency(currencyB, chainId),
      ]),
    [chainId, currencies],
  )

  useEffect(() => {
    if(isZk){
      const getZkPairAddresses = async () => {
        const addresses = await Promise.all(tokens.map(async ([tokenA, tokenB]) => tokenA && tokenB && !tokenA.equals(tokenB) ? await getZkLpAddress(tokenA, tokenB) : undefined))
        setZkPairAddresses(addresses)
      }
      getZkPairAddresses()
    }else{
      setZkPairAddresses([])
    }
  }, [isZk,tokens])

  const pairAddresses = useMemo(
    () =>{
      return isZk? zkPairAddresses : tokens.map(([tokenA, tokenB]) => {
        try {
          return tokenA && tokenB && !tokenA.equals(tokenB) ? Pair.getAddress(tokenA, tokenB) : undefined
        } catch (error: any) {
          // Debug Invariant failed related to this line
          console.error(
            error.msg,
            `- pairAddresses: ${tokenA?.address}-${tokenB?.address}`,
            `chainId: ${tokenA?.chainId}`,
          )

          return undefined
        }
      })
    },
    [tokens,zkPairAddresses],
  )

  const results = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'getReserves')

  return useMemo(() => {
    if(tokens.length===0)return []
    return results.map((result, i) => {
      const { result: reserves, loading } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]

      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves) return [PairState.NOT_EXISTS, null]
      const { reserve0, reserve1 } = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      return [
        PairState.EXISTS,
        new Pair(
          CurrencyAmount.fromRawAmount(token0, reserve0.toString()),
          CurrencyAmount.fromRawAmount(token1, reserve1.toString()),
        ),
      ]
    })
  }, [results, tokens])
}

export function usePairsWithLiquidtyToken(currencies: {liquidityToken:Currency,tokens:[Currency,Currency]}[]): [PairState, Pair | null][] {
  const { chainId } = useActiveChainId()
  const isZk = zkChains.includes(chainId)

  const tokens = useMemo(
    () =>
      currencies.map((p) => [
        wrappedCurrency(p.tokens[0], chainId),
        wrappedCurrency(p.tokens[1], chainId),
        wrappedCurrency(p.liquidityToken, chainId)
      ]),
    [chainId, currencies],
  )

  const pairAddresses = useMemo(
    () =>{
      return tokens.map(([tokenA, tokenB,tokenP]) => {
        return tokenA && tokenB && !tokenA.equals(tokenB) ? tokenP.address: undefined
      })
    },
    [tokens],
  )

  const results = useMultipleContractSingleData(pairAddresses, PAIR_INTERFACE, 'getReserves')

  return useMemo(() => {
    return results.map((result, i) => {
      const { result: reserves, loading } = result
      const tokenA = tokens[i][0]
      const tokenB = tokens[i][1]

      if (loading) return [PairState.LOADING, null]
      if (!tokenA || !tokenB || tokenA.equals(tokenB)) return [PairState.INVALID, null]
      if (!reserves) return [PairState.NOT_EXISTS, null]
      const { reserve0, reserve1 } = reserves
      const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]
      return [
        PairState.EXISTS,
        new Pair(
          CurrencyAmount.fromRawAmount(token0, reserve0.toString()),
          CurrencyAmount.fromRawAmount(token1, reserve1.toString()),
          isZk?tokens[i][2].address:undefined
        ),
      ]
    })
  }, [results, tokens])
}

export function usePair(tokenA?: Currency, tokenB?: Currency): [PairState, Pair | null] {
  const isZK = zkChains.includes(tokenA.chainId)
  const [pairToken, setPairToken] = useState<Currency>(new Token(tokenA.chainId,'',18,'peta-lp',"Petaverse LPs"))
  const pairCurrencies = useMemo<[Currency, Currency][]>(() => [[tokenA, tokenB]], [tokenA, tokenB])
  useEffect(() => {
    if (tokenA && tokenB) {
      if(isZK){
        const getZkLpAddress = async () => {
          setPairToken(await toZkLiquidityToken([tokenA.wrapped,tokenB.wrapped]))
        }
        getZkLpAddress()
      }else{
        setPairToken(toV2LiquidityToken([tokenA.wrapped,tokenB.wrapped]))
      }
    }else{
      setPairToken(new Token(tokenA.chainId,'',18,'peta-lp',"Petaverse LPs"))
    }
  }, [tokenA, tokenB])
  return usePairsWithLiquidtyToken([{tokens:pairCurrencies[0],liquidityToken:pairToken}])[0]
}
