import { useToast } from "@pancakeswap/uikit";
import axios, { AxiosResponse } from "axios";
import { CHAIN_QUERY_NAME } from "config/chains";
import { useAtom } from "jotai";
import { RESET, atomWithStorage } from "jotai/utils";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAccount, useChainId, useSignMessage } from "wagmi";

declare module 'axios' {
    export interface AxiosRequestConfig<D = any> {
        chainId?: number | string;
        customToken?: string | 'web3' | 'web2';
        showError?: boolean;
        throwError?: boolean;
    }
}

export interface Web2ApiResponse {
    code: number
}

export interface Web2ApiDataResponse<T> extends Web2ApiResponse {
    data: T
    message?: string | null
    status: number
}

export type FollowSource = 'twitter' | 'discord' | 'telegram'

export interface FollowTarget {
    source: FollowSource
    target: string
    name: string
    link: string
    code: string
}

export interface TwitterAuthInfo {
    userCode: string;
    tokenCode: string;
    error?: string;
}

export interface Web3User {
    chainId: number | string
    fullName: string | null
    id: string
    inviterCode: string
    jwt: string
    publicAddress: string
    role: string
    web2user: Web2User | null
    playerInfo: PlayerInfo
}

export interface Web2User {
    // playerInfo: PlayerInfo
    chatToken: string
    managerToken: string | null
    token: string
    userId: string
    snsId: number
}

export interface PlayerInfo {
    accountAddress: string
    talkPoint: number
    id: string
    state: string
}

export interface UserBindInfo {
    target: string
    systemUserId: string
    openId: string
    createdDate: string
    avatorUri: string | null
    email: string | null
    id: number
    source: string
    name: string
}

export interface UserActionInfo {
    actions: string
    target: string
    systemUserId: string
    openId: string
    createdDate: string
    extData: string
    id: number
    source: string
}

export interface UserInfos {
    actionInfos: UserActionInfo[]
    bindInfos: UserBindInfo[]
}

export const twitterAuthAtom = atomWithStorage<TwitterAuthInfo | null>(
    'twitterAuth',
    null, 
    // createJSONStorage(
    //     // getStringStorage
    //     () => localStorage, // or sesseionStorage, asyncStorage or alike
    //     // options (optional)
    //     // {
    //     //   reviver, // optional reviver option for JSON.parse
    //     //   replacer, // optional replacer option for JSON.stringify
    //     // },
    //   ),
    // {
    //     getOnInit:true
    // }
)

export const web3UserAtom = atomWithStorage<Web3User | null>('web3User', null)//,createJSONStorage(() => localStorage),{getOnInit:true})
export const inviterCodeAtom = atomWithStorage<Record<string, string>>(
    'inviterCodeMap',
    {}
)

export const useSetInviterCode = () => {
    const chainId = useChainId()
    const { query } = useRouter()
    const [_, updateInviterCode] = useAtom(inviterCodeAtom)
    useEffect(() => {
        if (query.code) {
            let setChainId = CHAIN_QUERY_NAME[chainId]
            if (query.chain) {
                setChainId = query.chain
            }
            console.log('inviterCode', setChainId)
            updateInviterCode((prev) => {
                return {
                    ...prev,
                    setChainId: query.code.toString(),
                }
            })
        }
    })
}

export const useInviterCode = () => {
    const chainId = useChainId()
    const [inviterCode, _] = useAtom(inviterCodeAtom)
    return useMemo(() => inviterCode[chainId], [chainId, inviterCode])
}


export function openWindow(url: string, name: string, iWidth: number, iHeight: number) {
    const iTop = (window.screen.availHeight - 30 - iHeight) / 2 //get window ver;
    const iLeft = (window.screen.availWidth - 10 - iWidth) / 2 //get window hor;
    window.open(url, name, `height=${iHeight},,innerHeight=${iHeight},width=${iWidth},innerWidth=${iWidth},top=${iTop},left=${iLeft},toolbar=no,menubar=no,scrollbars=auto,resizeable=no,location=no,status=no`)
}

export const web3Api = {
    nonce: (address: string) => `/api/Account/nonce/${address}`,
    subAddresses: (id: string) => `https://petaverse.store/adcx-api/web-api-v2/api/Account/getSubUsers/${id}`,
    login: '/api/Account/login',
    accountInfo: (id: string) => `/api/Account/${id}`,
    checkAndCreate: '/Petrip/UserPlayer/CheckAndCreate',
    getMetaDatas: '/Petrip/Assets/GetMetadataInfos',
    getPetDatas: '/Petrip/Assets/GetPetInfos',
    requestBuyNft: 'Petrip/Market/RequestBuyTool',
    notifyUseTool: 'Petrip/Market/UseTool',
    getCategory: '/Petrip/Market/GetCategory',
    getMarketList: '/Petrip/Market/GetMarketList',
    SendMessageToAi: '/Petrip/Chat/SendMessageToAi',
    getMapList: '/Petrip/Stake/GetMapList',
    requestStake: '/Petrip/Stake/StakeRequest',
    notifyStake: '/Petrip/Stake/StakeNotify',
    notifyReturn: '/Petrip/Stake/ReturnNotify',
    requestReturn: '/Petrip/Stake/ReturnRequest',
    getStakeList: '/Petrip/Stake/GetStakeStatus',
    getExchangePTCParams: '/Petrip/KycSwap/KycSwap',
    finishExchangePTC: '/Petrip/KycSwap/FinishSwap',
    GetMessageHistory: '/Petrip/Chat/GetMessageDetail',
    buyNotify: '/Petrip/Market/BuyNotify',
    getPetStakeStatus: '/Petrip/StakeV2/GetPetStakeStatus',
    notifyStakeV2: '/Petrip/StakeV2/StakeNotify',
    requestStakeV2: '/Petrip/StakeV2/StakeRequest',
    getUserInfo: '/Petrip/ThirdParty/GetUserInfo',
    getUserPoint: '/Petrip/UserPoint/GetUserPoint',
    getParticipants: '/Petrip/UserPoint/GetPointUserCount',
}

// export const appStore = createStore()

const http = axios.create({
    baseURL: 'https://petaverse.store/adcx-api/web-api-v2',
    timeout: 60000,
})

http.interceptors.request.use((config) => {
    console.info('web3 request', config)
    if (config.method === 'post') {
        config.headers['Content-Type'] = 'application/json'
    }
    let user = null
    const userStorageValue = localStorage.getItem('web3User')
    if (userStorageValue) {
        user = JSON.parse(userStorageValue) as Web3User
    }
    if (user) {
        config.headers.Authorization = `Bearer ${user.jwt}`
    }
    if (config.customToken) {
        config.headers.Authorization = `Bearer ${config.customToken}`
    }
    return config
})

export const useAxiosResponseInterceptor = () => {
    const [_, setWeb3User] = useAtom(web3UserAtom)
    const { toastError } = useToast()

    const handleResponseInterceptor = useCallback(
        (response: AxiosResponse<any, any>, tag: string) => {
            console.info(`${tag} response:`, response)
            if (
                typeof response.data === 'object'
                &&
                response.data.hasOwnProperty('code')
            ) {
                if (response.data.code !== 0) {
                    const errorMsg = response.data?.message || response.data?.msg || 'Network Error'
                    if (response.data.code === 401) {
                        console.log('401')
                        setWeb3User(RESET)
                        toastError('You need to log in',)
                    }

                    if (response.config.showError !== false) {
                        toastError( errorMsg)
                    }
                    if (response.config.throwError === false) {
                        return response
                    } else {
                        return Promise.reject(new Error(errorMsg))
                    }
                } else {
                    return response
                }
            } else {
                return response
            }
        },
        [setWeb3User, toastError]
    )

    useEffect(() => {
        http.interceptors.response.clear()
        http.interceptors.response.use((response) =>
            handleResponseInterceptor(response, 'web3')
        )
    }, [handleResponseInterceptor])
}

export async function getUserPoints(): Promise<number> {
    const { data } = await http.post<Web2ApiDataResponse<number>>(web3Api.getUserPoint)
    return data.data
}

export async function getUserInfos(): Promise<UserInfos> {
    const { data } = await http.post<Web2ApiDataResponse<UserInfos>>(web3Api.getUserInfo)
    return data.data
}

export async function checkTwitterFollow(body: {
    tokenCode: string
    userCode: string
    projectCode: string
}): Promise<boolean> {
    const { data } = await http.post(
        `${process.env.NEXT_PUBLIC_THIRDPARTY}/Twitter/CheckFollow`,
        body,
        {
            params: {
                providerName: 'twitter',
                projectCode: body.projectCode,
            },
        }
    )
    return data?.data
}

export interface NonceResponse {
    publicAddress: `0x${string}`;
    nonce: number;
}


export async function getNonce(
    address: string,
    chainId: number | string
): Promise<NonceResponse> {
    const { data } = await http.get<NonceResponse>(web3Api.nonce(address), {
        chainId,
    })
    return data
}

export interface PlayerInfoResponse extends Web2ApiResponse {
    playerInfo: PlayerInfo;
}


export async function checkAndCreate(
    chainId: number | string,
    token?: string
): Promise<PlayerInfoResponse> {
    const { data } = await http.post<PlayerInfoResponse>(
        web3Api.checkAndCreate,
        {},
        {
            chainId,
            timeout: 60000,
            customToken: token,
        }
    )
    return data
}

export interface Web3LoginResponse {
    fullName: string | null;
    id: string;
    inviterCode: string;
    jwt: string;
    publicAddress: string;
    role: string;
}

export async function web3Login(params: {
    publicAddress: string;
    inviterCode?: string;
    signature: string;
    chainId: number | string;
    source?: string;
}): Promise<Web3LoginResponse> {
    const { data } = await http.post<Web3LoginResponse>(web3Api.login, params, {
        chainId: params.chainId,
        showError: false,
    })

    return data
}

export function useCheckLogin(){
    const chainId = useChainId()
    const [web3User, setWeb3User] = useAtom(web3UserAtom)
    const isLogin = useMemo(() => !!web3User, [web3User])

    const [isApiLoading, setIsApiLoading] = useState(false)
    const inviterCode = useInviterCode()
    const [, setTwitterAuth] = useAtom(twitterAuthAtom)

    const { toastError, toastSuccess } = useToast()

    const { signMessageAsync } = useSignMessage()

    const handleLogin = useCallback(async (address:string) => {
        if (!isLogin || web3User?.publicAddress.toLowerCase() !== address?.toLowerCase()) {
            // sign login
            try {
                setIsApiLoading(true)
                const nonceData = await getNonce(address || '', chainId)
                const signedNonce = await signMessageAsync({
                    message: nonceData.nonce.toString(),
                })
                const loginRes = await web3Login({
                    publicAddress: address || '',
                    signature: signedNonce,
                    inviterCode,
                    chainId,
                    source: 'task_apprecia',
                })
                const playerInfo = await checkAndCreate(chainId, loginRes.jwt)
                setWeb3User({
                    web2user: null,
                    chainId,
                    ...loginRes,
                    playerInfo: playerInfo.playerInfo,
                })
                setTwitterAuth(RESET)
                toastSuccess('Login Success')
            } catch (error) {
                console.log('Login/sign Failed', error)
                toastError('Failed to sign the message or Login Failed')
            } finally {
                setIsApiLoading(false)
            }
        }
    }, [isLogin, web3User?.publicAddress, setWeb3User, chainId, inviterCode, setTwitterAuth, toastSuccess, toastError])

    useAccount({
        onConnect({ address, connector, isReconnected, }) {
            console.log('onConnect',isReconnected,address)
            handleLogin(address)
        },
        onDisconnect() {
            setWeb3User(RESET)
        },
    })

    return{
        isLogin,
        handleLogin,
        isLoading:isApiLoading
    }
}