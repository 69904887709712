import { BigNumber } from '@ethersproject/bignumber'
import { Pool } from '@pancakeswap/uikit'
import { SerializedWrappedToken } from '@pancakeswap/token-lists'
import {TEST_ZKSYNC_TESTNET, PETA_ZKSYNC, PETA_ZKSYNC_TESTNET, PETA_LP_ZKSYNC_TESTNET} from "@pancakeswap/tokens";
import { ChainId } from '@pancakeswap/sdk'
import { WETH9 } from '@pancakeswap/sdk/src'
import { PoolCategory } from './types'

export const MAX_LOCK_DURATION = 31536000
export const UNLOCK_FREE_DURATION = 604800
export const ONE_WEEK_DEFAULT = 604800
export const BOOST_WEIGHT = BigNumber.from('20000000000000')
export const DURATION_FACTOR = BigNumber.from('31536000')

export const vaultPoolConfig = {} as const

// v2 staking 0x856BE368d94dA876a23B1283ce0b97A0Dbb42996

export const livePools: Pool.SerializedPoolConfig<SerializedWrappedToken>[] = [
  {
    sousId: 18,
    poolIndex: 2,
    showIndex: 2,
    stakingToken: PETA_ZKSYNC,
    earningToken: PETA_ZKSYNC,
    contractAddress: {
      324: '0xe6043E5b2B4A3D7aA9DE062D558AbA3DfC618675',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '9000000',// total reward
    isFinished: false,
    isMiracle: false,
    tokenA: undefined,
    tokenB: undefined,
  },
  {
    sousId: 23,
    poolIndex: 7,
    showIndex: 2,
    stakingToken: TEST_ZKSYNC_TESTNET,
    earningToken: TEST_ZKSYNC_TESTNET,
    contractAddress: {
      280: '0x25964488461b1cbFA0FbcBF39B1A157E2D068A33',
    },
    poolCategory: PoolCategory.STAKINGPOOLV2,
    tokenPerBlock: '14400',
    isFinished: false,
    isMiracle: false,
    tokenA: undefined,
    tokenB: undefined,
  },
  {
    sousId: 24,
    poolIndex: 9,
    showIndex: 2,
    stakingToken: PETA_LP_ZKSYNC_TESTNET,
    earningToken: PETA_ZKSYNC_TESTNET,
    contractAddress: {
      280: '0x25964488461b1cbFA0FbcBF39B1A157E2D068A33',
    },
    poolCategory: PoolCategory.STAKINGPOOLV2,
    tokenPerBlock: '14400',
    isFinished: false,
    isMiracle: true,
    tokenA: PETA_ZKSYNC_TESTNET,
    tokenB: WETH9[ChainId.ZKSYNC_TESTNET],
  },
].map((p) => ({
  ...p,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
  tokenA: p.tokenA ? p.tokenA.serialize : undefined,
  tokenB: p.tokenB ? p.tokenB.serialize : undefined,
}))

// known finished pools
const finishedPools: Pool.SerializedPoolConfig<SerializedWrappedToken>[] = [
  {
    sousId: 17,
    poolIndex: 0,
    showIndex: 1,
    stakingToken: PETA_ZKSYNC,
    earningToken: PETA_ZKSYNC,
    contractAddress: {
      324: '0xe6043E5b2B4A3D7aA9DE062D558AbA3DfC618675',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '3000000',// total reward
    isMiracle: false,
    tokenA: undefined,
    tokenB: undefined,
  },
  {
    sousId: 21,
    poolIndex: 4,
    showIndex: 1,
    stakingToken: TEST_ZKSYNC_TESTNET,
    earningToken: TEST_ZKSYNC_TESTNET,
    contractAddress: {
      280: '0x25964488461b1cbFA0FbcBF39B1A157E2D068A33',
    },
    poolCategory: PoolCategory.STAKINGPOOLV2,
    tokenPerBlock: '604800',
    isMiracle: false,
    tokenA: undefined,
    tokenB: undefined,
  },
  {
    sousId: 22,
    poolIndex: 5,
    showIndex: 1,
    stakingToken: PETA_LP_ZKSYNC_TESTNET,
    earningToken: PETA_ZKSYNC_TESTNET,
    contractAddress: {
      280: '0x25964488461b1cbFA0FbcBF39B1A157E2D068A33',
    },
    poolCategory: PoolCategory.STAKINGPOOLV2,
    tokenPerBlock: '604800',
    isMiracle: true,
    tokenA: PETA_ZKSYNC_TESTNET,
    tokenB: WETH9[ChainId.ZKSYNC_TESTNET],
  }].map((p) => ({
  ...p,
  isFinished: true,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
  tokenA: p.tokenA ? p.tokenA.serialize : undefined,
  tokenB: p.tokenB ? p.tokenB.serialize : undefined,
}))
export default [...livePools, ...finishedPools] as Pool.SerializedPoolConfig<SerializedWrappedToken>[]
