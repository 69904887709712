import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  TradeIcon,
  TradeFilledIcon,
  SwapFillIcon, 
  DropdownMenuItems, 
  InfoFilledIcon,
  InfoIcon,
  CurrencyIcon,
  HomeIcon,
  BridgeIcon,
  EarnIcon,
  EarnFillIcon,
  PencilIcon,
  CommunityIcon,
} from "@pancakeswap/uikit";
import { ContextApi } from '@pancakeswap/localization';

export type ConfigMenuDropDownItemsType = DropdownMenuItems & {
  hideSubNav?: boolean;
  isComingSoon?: boolean;
}

export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & {
  isComingSoon?: boolean;
  hideSubNav?: boolean;
  image?: string;
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) => {
  const items: ConfigMenuItemsType[] = [
    {
      label: t('Home'),
      icon: HomeIcon,
      fillIcon: HomeIcon,
      href: '/',
      hideSubNav: true,
      showItemsOnMobile: false,
      items: [],
    },
    {
      label: t('MEME'),
      href: '/filo/meme',
      icon: CommunityIcon,
      isComingSoon: false,
      fillIcon: CommunityIcon,
      hideSubNav: true,
      showItemsOnMobile: false,
      items: [],
    },
    {
      label: t('RWA'),
      href: '/rwa',
      icon: CurrencyIcon,
      isComingSoon: false,
      fillIcon: CurrencyIcon,
      hideSubNav: true,
      showItemsOnMobile: false,
      items: [],
    },
    // {
    //   label: t('Edu'),
    //   href: '/filo/edu',
    //   icon: PencilIcon,
    //   isComingSoon: false,
    //   fillIcon: PencilIcon,
    //   hideSubNav: true,
    //   showItemsOnMobile: false,
    //   items: [],
    // },
    {
      label: t('Bank'),
      // href: '/pools',
      href: '/filo/bank',
      icon: EarnIcon,
      fillIcon: EarnFillIcon,
      hideSubNav: true,
      showItemsOnMobile: false,
      items: [],
    },
    // {
    //   label: t('Swap'),
    //   icon: SwapIcon,
    //   fillIcon: SwapFillIcon,
    //   href: '/swap' || '/liquidity',
    //   hideSubNav: true,
    //   showItemsOnMobile: true,
    //   items: [{
    //       label: t('Swap'),
    //       href: '/swap',
    //     },
    //     {
    //       label: t('Liquidity'),
    //       href: '/liquidity',
    //     },
    //   ]
    // },
    // {
    //   label:t('Bridge'),
    //   href: '',
    //   isComingSoon: true,
    //   hideSubNav: true,
    //   showItemsOnMobile: false,
    //   icon: BridgeIcon,
    //   fillIcon: BridgeIcon,
    //   items: [],
    // },
    // {
    //   label: t('Smart Money'),
    //   href: '/filo/smart',
    //   icon: TradeIcon,
    //   fillIcon: TradeFilledIcon,
    //   hideSubNav: true,
    //   showItemsOnMobile: false,
    //   items: [],
    // },
    {
      label: t('More'),
      href: '',
      icon: InfoIcon,
      fillIcon: InfoFilledIcon,
      hideSubNav: true,
      showItemsOnMobile:true,
      items: [
        {
          label: t('Edu'),
          href: '/filo/edu',
        },
        {
          label: t('Swap'),
          href: '/swap',
        },
        {
          label: t('Liquidity'),
          href: '/liquidity',
        },
        {
          label:t('Bridge'),
          href: '',
          isComingSoon: true,
        },
        {
          label: t('Smart Money'),
          href: '/filo/smart',
        }
        // {
        //   label: 'Quest',
        //   href: '/quest',
        //   type: DropdownMenuItemType.INTERNAL_LINK,
        // },
        // {
        //   label: t('Roadmap'),
        //   href: 'https://aiswap-1.gitbook.io/aiswap/roadmap',
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
        // {
        //   label: 'Whitepaper',
        //   href: 'https://aiswap-1.gitbook.io/',
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
        // {
        //   label: 'AiSwap',
        //   href: 'https://twitter.com/AI_RWASwap',
        //   disabled: false,
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
  ];
  return items.map((item) => addMenuItemSupported(item, chainId));
}
export default config