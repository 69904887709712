import { ERC20Token } from '@pancakeswap/sdk'
import { readContract } from '@wagmi/core'
import memoize from 'lodash/memoize'
import { FACTORY_ADDRESS_MAP } from '@pancakeswap/sdk/src'
import PairABI from 'config/abi/pair.json'
import { useContract } from "../hooks/useContract";
import { useProvider } from 'wagmi'
import { getAddress } from 'ethers/lib/utils'

const lpCache = new Map<string, string>();

export const getZkLpAddress = async ( token1: ERC20Token,token2: ERC20Token) =>{
  const key = `${token1?.address || token1}#${token2?.address || token2}#${token1.chainId}`
  if(lpCache.has(key)){
    return lpCache.get(key)
  }
  const lpAddressData = await readContract({
    chainId: token1.chainId,
    address:getAddress(FACTORY_ADDRESS_MAP[token1.chainId]),
    abi:PairABI,
    functionName: 'getPair',
    args:[token1.address, token2.address]
    }
  )
  const lpAddress = lpAddressData.toString()
  if(lpAddress != '0x0000000000000000000000000000000000000000')
    lpCache.set(key, lpAddress)
  return lpAddress
}

export const getZkLpAddressSync = ( token1: ERC20Token,token2: ERC20Token) =>{
  const key = `${token1?.address || token1}#${token2?.address || token2}#${token1.chainId}`
  if(lpCache.has(key)){
    return lpCache.get(key)
  }
  return null
}

export default getZkLpAddress
